import React, { useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
// toast
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Footer } from "./component/Footer";
import LandingPage from "./component/LandingPage";
import About from "./page/About";
import Event from "./page/Events";
import AliSirData from "./page/alisir";
import OfferingData from "./page/sachinsir";
import Services from "./container/Services";
import CreerPortal from "./page/careerportal";
import ContactForm from "./page/ContactForm";
import Designedby from "./utils/designedby";
import HardWare from "./container/hardware";
import Header from "./component/Header";
const App = () => {
  const pathname = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [pathname]);

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Navigate to={"/home"} />} />
        <Route path="/home" element={<LandingPage />} />
        <Route path="/about_us" element={<About />} />
        <Route path="/developedby" element={<Designedby />} />
        <Route path="/services" element={<Services />} />
        <Route path="/sequro_events" element={<Event />} />
        <Route path="/career_portal" element={<CreerPortal />} />
        <Route path="/contact-us" element={<ContactForm />} />
        <Route path="/about-team" element={<OfferingData />} />
        <Route path="/hardware-products" element={<HardWare />} />
        <Route path="/about-team-director" element={<AliSirData />} />
        <Route path="*" element={<Navigate to={"/home"} />} />
      </Routes>
      <Footer />

      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        theme="colored"
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
export default App;
