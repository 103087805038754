import React from "react";
import Heading from "../utils/Heading";
import { CheckCircleIcon } from "@heroicons/react/solid";
const About = () => {
  return (
    <div>
      <Heading
        title="About - Sequro Technologies"
        description="Sequro is a leading IT company in India, providing a wide range of
              IT services and solutions to clients across the globe. We are
              committed to providing the best IT services to our clients and
              partners. We have a team of highly skilled and experienced
              professionals who are dedicated to providing the best "
      />
      <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4 bg-blue-50" onMouseDown={(e)=>e.preventDefault()} onContextMenu={(e)=>e.preventDefault()}>
        <div className=" w-full">
          <div className="mb-8 text-center">
            <h1 className="text-4xl font-bold text-blue-500">Who We Are</h1>
          </div>
          <div className=" px-4  my-4 ">
            <p className=" flex  my-2">
              <CheckCircleIcon className="w-6 h-6 text-blue-500" />
              <span className="ml-2">
                A Payments Solution offering Company incorporated in Bangalore
                in the year 2017 with presence in 3 Cities i.e. Bangalore,
                Mumbai and Chennai
              </span>
            </p>
            <p className=" flex  my-2">
              <CheckCircleIcon className="w-6 h-6 text-blue-500" />
              <span className="ml-2">
                With a collective experience of more than 250+ man years we have
                built in-house Payments Solutions to cater to today’s Digital
                Ecosystem{" "}
              </span>
            </p>
            <p className=" flex  my-2">
              <CheckCircleIcon className="w-6 h-6 text-blue-500" />
              <span className="ml-2">
                Empanelled with NPCI to deliver strategic initiated projects and
                contribute in Process Automation initiatives of NPCI for
                operational efficiency{" "}
              </span>
            </p>
            <p className=" flex  my-2">
              <CheckCircleIcon className="w-6 h-6 text-blue-500" />
              <span className="ml-2">
                Delivering Payments solution to international client for both
                Issuing and Acquiring side of Business
              </span>
            </p>
            <p className=" flex  my-2">
              <CheckCircleIcon className="w-6 h-6 text-blue-500" />
              <span className="ml-2">
                In-house Technical expertise for Open Source Technologies with
                good command over the Machine Learning and Artificial
                Intelligence
              </span>
            </p>
            <p className=" flex  my-2">
              <CheckCircleIcon className="w-6 h-6 text-blue-500" />
              <span className="ml-2">
                Strong Domain expertise of BFSI spreading across Payments and
                Banking solutions
              </span>
            </p>
            <p className=" flex  my-2">
              <CheckCircleIcon className="w-6 h-6 text-blue-500" />
              <span className="ml-2">
                A strategic tie-up with OEM, a China based device manufacturer
                for Point Of Sale and other hardware solutions which includes
                Face detection, Sound box, Retail Tabs and so on.
              </span>
            </p>
          </div>
          <p className=" text-base  text-gray-600 mt-6  ">
            Sequro continously explores the external environment to understand
            the emerging technologies, reguatory and business requirements, etc.
            to updgrade products and meet the requirements of our clients. We
            believe in providing quality solutions at competitive prices. We
            have bouquet of solutions, Which cater to the needs of Banking &
            Payments, Non-BFSI, ERP, FinTech and other.
          </p>
        </div>

        {/* <div className="lg:mt-14 sm:mt-10 mt-12">
          <img
            className="lg:block hidden w-full"
            src="https://i.ibb.co/GvwJnvn/Group-736.png"
            alt="Group of people Chilling"
          />
          <img
            className="lg:hidden sm:block hidden w-full"
            src="https://i.ibb.co/5sZTmHq/Rectangle-116.png"
            alt="Group of people Chilling"
          />
          <img
            className="sm:hidden block w-full"
            src="https://i.ibb.co/zSxXJGQ/Rectangle-122.png"
            alt="Group of people Chilling"
          />
        </div> */}
      </div>
    </div>
  );
};

export default About;
