import React from "react";
import facebook from "../assests/facebook.png";
import twitter from "../assests/twitter.png";
import linkdin from "../assests/linkedin.png";
import github from "../assests/github.png";
import youtube from "../assests/youtubee.png";
export const Footer = () => {
  const y = new Date();
  let year = y.getFullYear();

  return (
    <footer className="bg-gray-900 dark:bg-gray-800" onMouseDown={(e)=>e.preventDefault()} onContextMenu={(e)=>e.preventDefault()}>
      <div className="container px-6 py-8 mx-auto">
        <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          <div>
            <div className="text-xs font-medium text-blue-200 uppercase">
              Sequro Technologies Pvt.Ltd
            </div>
            <a
              href="https://goo.gl/maps/qRqVSLeEfCHziVB79"
              target="_blank"
              rel="noreferrer"
            >
              <p className="block mt-5 text-sm font-medium text-gray-500 duration-700 dark:text-gray-300 hover:text-green-300 dark:hover:text-gray-200 hover:underline ">
                <b className="text-xs font-medium text-white uppercase">
                  Head Office
                </b>
                : SEQURO TECHNOLOGIES PVT. LTD 339, 1st Floor, 4th Cross Rd,
                Arekere MICO Layout 2nd stage, Lakshmi Layout, Arekere,
                Bengaluru, Karnataka 560076
              </p>
            </a>
            <a
              href="https://goo.gl/maps/XFh17coTbCjNVBRM7"
              target="_blank"
              rel="noreferrer"
            >
              <p className="block mt-5 text-sm  text-gray-500 duration-700 dark:text-gray-300 hover:text-gray-400 dark:hover:text-gray-200 hover:underline ">
                <b className="text-xs font-medium text-gray-300 uppercase">
                  Corporate Office
                </b>
                : Sagar Tech Plaza, A-719, Andheri Sakinaka, Link
                Road, near Sakinaka, Andheri East, Mumbai, Maharashtra 400072
              </p>
            </a>

            <p className="block mt-5 text-sm font-medium text-gray-500 duration-700 dark:text-gray-300 hover:text-green-300 dark:hover:text-gray-200 hover:underline ">
              <b className="text-xs font-medium text-white uppercase">
                Branch Office
              </b>
              : Travessa do Chafariz N18-3º Direito, 2685-035, Sacavém, Lisbon,
              Portugal{" "}
              <div className="flex text-gray-300 font-light py-1.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className=" w-5 h-5 "
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z"
                  />
                </svg>
                +351920453528
              </div>{" "}
            </p>

            <a
              href="mailto:contactus@sequrotechnologies.com"
              className="block mt-3 text-sm font-medium text-gray-500 duration-700 dark:text-gray-300 hover:text-green-300 dark:hover:text-gray-200 hover:underline"
            >
              <b className="text-xs font-medium text-gray-400 uppercase">
                Email Us
              </b>
              : contactus@sequrotechnologies.com
            </a>
          </div>

          <div>
            <div className="text-xs font-medium text-blue-200 uppercase">
              <p>Our Technical Expertise Gives Your Business a Competitive</p>
            </div>

            <a
              href={`/services`}
              className="block mt-5 text-sm font-medium text-gray-500 duration-700 dark:text-gray-300 hover:text-green-300 dark:hover:text-gray-200 hover:underline"
            >
              Custom Software
            </a>
            <a
              href={`/services`}
              className="block mt-3 text-sm font-medium text-gray-500 duration-700 dark:text-gray-300 hover:text-green-300 dark:hover:text-gray-200 hover:underline"
            >
              UX UI Design
            </a>
            <a
              href={`/services`}
              className="block mt-3 text-sm font-medium text-gray-500 duration-700 dark:text-gray-300 hover:text-green-300 dark:hover:text-gray-200 hover:underline"
            >
              3rd Party Integrations
            </a>
            <a
              href={`/services`}
              className="block mt-3 text-sm font-medium text-gray-500 duration-700 dark:text-gray-300 hover:text-green-300 dark:hover:text-gray-200 hover:underline"
            >
              Cloud Development
            </a>
            <a
              href={`/services`}
              className="block mt-3 text-sm font-medium text-gray-500 duration-700 dark:text-gray-300 hover:text-green-300 dark:hover:text-gray-200 hover:underline"
            >
              DevOps
            </a>
            <a
              href={`/services`}
              className="block mt-3 text-sm font-medium text-gray-500 duration-700 dark:text-gray-300 hover:text-green-300 dark:hover:text-gray-200 hover:underline"
            >
              Mobile App Development
            </a>
          </div>

          <div>
            <div className="text-xs font-medium text-blue-200 uppercase">
              Products & Solutions Offerings
            </div>
            <a
              href={`/services`}
              className="block mt-5 text-sm font-medium text-gray-400 duration-700 dark:text-gray-300 hover:text-green-400 dark:hover:text-gray-200 hover:underline"
            >
              <b className="text-xs font-medium text-white ">MMS</b> : Merchant
              Mangement System
            </a>
            <a
              href={`/services`}
              className="block mt-5 text-sm font-medium text-gray-400 duration-700 dark:text-gray-300 hover:text-green-400 dark:hover:text-gray-200 hover:underline"
            >
              <b className="text-xs font-medium text-white ">MOP</b> : Merchant
              Portal
            </a>
            <a
              href={`/services`}
              className="block mt-5 text-sm font-medium text-gray-400 duration-700 dark:text-gray-300 hover:text-green-400 dark:hover:text-gray-200 hover:underline"
            >
              <b className="text-xs font-medium text-white ">RaaS</b> : Risk As
              A Service
            </a>
            <a
              href={`/services`}
              className="block mt-5 text-sm font-medium text-gray-400 duration-700 dark:text-gray-300 hover:text-green-400 dark:hover:text-gray-200 hover:underline"
            >
              <b className="text-xs font-medium text-white ">STaaS</b> : Suspect
              Tracking As A Service
            </a>
            <a
              href={`/services`}
              className="block mt-5 text-sm font-medium text-gray-400 duration-700 dark:text-gray-300 hover:text-green-400 dark:hover:text-gray-200 hover:underline"
            >
              <b className="text-xs font-medium text-white ">PHR</b> : Payment
              Hold/Release Management
            </a>
          </div>
          <div>
            <div className="text-xs font-medium text-blue-200 uppercase">
              Our Branch
            </div>
            <a
              href="https://goo.gl/maps/SPvXeh7YHPL5Apjq8"
              target={"_blank"}
              rel="noreferrer"
              className="block mt-5 text-sm font-medium text-gray-400 duration-700 dark:text-gray-300 hover:text-green-400 dark:hover:text-gray-200 hover:underline"
            >
              Bengaluru
            </a>
            <a
              href="https://goo.gl/maps/C5LRsjpXnCxaEwr78"
              target={"_blank"}
              rel="noreferrer"
              className="block mt-5 text-sm font-medium text-gray-400 duration-700 dark:text-gray-300 hover:text-green-400 dark:hover:text-gray-200 hover:underline"
            >
              Mumbai
            </a>
            <a
              href="/"
              rel="noreferrer"
              className="block mt-5 text-sm font-medium text-gray-400 duration-700 dark:text-gray-300 hover:text-green-400 dark:hover:text-gray-200 hover:underline"
            >
              Chennai
            </a>
            <a
              href="/"
              rel="noreferrer"
              className="block mt-5 text-sm font-medium text-gray-400 duration-700 dark:text-gray-300 hover:text-green-400 dark:hover:text-gray-200 hover:underline"
            >
              Portugal
            </a>
          </div>
        </div>

        <hr className="my-10 border-gray-200 dark:border-gray-400"></hr>

        <div className="sm:flex sm:items-center sm:justify-between">
          <p className="text-sm text-gray-400">
            Copyright ©Sequro Technologies Pvt. Ltd : {year}. All Rights
            Reserved.
          </p>

          <div className="flex mt-3 -mx-2 sm:mt-0">
            <a
              href="/"
              rel="noreferrer"
              className="mx-2 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
              aria-label="Reddit"
            >
              <img
                className="w-5 h-5 fill-current"
                alt="loading."
                src={twitter}
              />
            </a>
            <a
              href="/"
              rel="noreferrer"
              className="mx-2 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
              aria-label="Reddit"
            >
              <img
                className="w-5 h-5 fill-current"
                alt="loading."
                src={github}
              />
            </a>

            <a
              href="https://www.linkedin.com/company/sequro-technologies-pvt-ltd/"
              rel="noreferrer"
              className="mx-2 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
              aria-label="Facebook"
            >
              <img
                className="w-5 h-5 fill-current"
                alt="loading."
                src={linkdin}
              />
            </a>

            <a
              href="/"
              rel="noreferrer"
              className="mx-2 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
              aria-label="Github"
            >
              <img
                className="w-5 h-5 fill-current"
                alt="loading."
                src={youtube}
              />
            </a>
            <a
              href="/"
              rel="noreferrer"
              className="mx-2 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
              aria-label="Github"
            >
              <img
                className="w-5 h-5 fill-current"
                alt="loading."
                src={facebook}
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
