import React from "react";
import { Link } from "react-router-dom";
import ContactForm from "../page/ContactForm";
import { PencilAltIcon } from "@heroicons/react/solid";

const HardWare = () => {
  return (
    <div className="bg-gray-100" onMouseDown={(e)=>e.preventDefault()} onContextMenu={(e)=>e.preventDefault()}>
      <div className="mx-auto container">
        <div className="md:mx-auto md:container px-4">
          {/* product section start */}
          <div className=" px-2 xl:px-0 py-12 my-1">
            <div className="mx-auto container">
              <h1 className="text-2xl lg:text-4xl md:leading-snug tracking-tighter f-f-l font-black text-center">
                HARDWARE PRODUCTS
              </h1>
              <div className="justify-center grid sm:grid-cols-2 md:grid-cols-2  gap-8 my-8">
                <div className="mt-10 grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-8">
                  <div>
                    <div className="py-2 px-4 w-full flex justify-between bg-blue-900">
                      <p className="text-sm text-white font-semibold tracking-wide">
                        Tianyu POS & mPOS
                      </p>
                    </div>
                    <div className="bg-white px-3  lg:px-6 rounded-bl-3xl rounded-br-3xl">
                      <p className=" flex  my-2">
                        A POS system allows your business to accept payments
                        from customers and keep track of sales. It sounds simple
                        enough, but the setup can work in different ways,
                        depending on whether you sell online, have a physical
                        storefront, or both. A POS (Point of Sale) system is the
                        combination of software and hardware to facilitate your
                        business operations
                      </p>
                      <Link
                        className="text-blue-400"
                        onClick={ContactForm}
                        to={`/contact-us`}
                      >
                        know more....
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="mt-10 grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-8">
                  <div>
                    <div className="py-2 px-4 w-full flex justify-between bg-blue-900">
                      <p className="text-sm text-white font-semibold tracking-wide">
                        QR Sound Box
                      </p>
                    </div>
                    <div className="bg-white px-3  lg:px-6 rounded-bl-3xl rounded-br-3xl">
                      <p className=" flex  my-2">
                        <PencilAltIcon className="w-6 h-6 text-blue-500" />
                        <span className="ml-2">
                          Widely Used In Supermarkets, Restaurants, Convenience
                          Stores, Street Stalls and other Scenarios .
                        </span>
                      </p>
                      <p className=" flex  my-2">
                        <PencilAltIcon className="w-6 h-6 text-blue-500" />
                        <span className="ml-2">
                          Automatically Receives and Plays Payment Information,
                          with Multiple Leak-Proof Broadcast Mechanisms{" "}
                        </span>
                      </p>
                      <p className=" flex  my-2">
                        <PencilAltIcon className="w-6 h-6 text-blue-500" />
                        <span className="ml-2">
                          Has Human Voice Playback, Can Customize Different
                          Playback Styles, Support Remote Voice Change with
                          multi-lingual supported.
                        </span>
                      </p>
                      <Link
                        className="text-blue-400"
                        onClick={ContactForm}
                        to={`/contact-us`}
                      >
                        know more....
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HardWare;
