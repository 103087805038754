import React from "react";
import dilshaddev from "../assests/dilshaddev.jpeg";
const Designedby = () => {
  return (
    <div>
      <div className="dark:bg-gray-900 ">
        <div className="flex flex-col lg:flex-row items-stretch justify-between lg:px-0 px-6 lg:py-20 py-8 2xl:mx-auto 2xl:container">
          <div className="z-30 relative lg:w-1/2 my-10">
            <div className="hidden dark:bg-gray-800 bg-gray-100 w-full lg:w-10/12 lg:h-full lg:flex justify-end items-center">
              <div className="w-full lg:w-auto lg:-mr-32 ">
                <img
                  src={dilshaddev}
                  alt="loading .."
                  className="w-full relative z-30 lg:pl-20 px-6 py-14"
                />
              </div>
            </div>
            <div className="absolute top-0 dark:bg-gray-800 bg-gray-100 md:h-96 w-full hidden md:block lg:hidden"></div>
            <div className="w-full h-full lg:hidden">
              <img
                src={dilshaddev}
                alt=" with decent chairs"
                className="w-full relative z-30 lg:pl-20 md:px-6 py-5 md:py-14"
              />
            </div>
          </div>
          <div className="bg-gray-100 dark:bg-gray-800 lg:w-1/2 lg:ml-12 lg:p-14 p-8 flex items-center">
            <div>
              <h1 className="dark:text-white md:w-8/12 lg:w-10/12 xl:8/12 2xl:w-8/12 w-full xl:text-4xl sm:text-3xl text-3xl font-semibold text-gray-800 capitalize">
                Er Dilshad Azam
              </h1>
              <p className="dark:text-gray-300 md:w-9/12 lg:w-11/12 xl:w-10/12 2xl:9/12 text-base leading-normal text-gray-600 mt-5">
                Being a diligent, hardworking and result oriented Men, I always
                work towards achieving best result on each project I lay my
                hands on.
              </p>
              <a
                href="https://dilshadazam.github.io/portfolio/"
                target="_blank"
                rel="noreferrer"
              >
                <button className="dark:bg-white dark:hover:bg-gray-400 dark:hover:text-gray-100 dark:text-gray-800 sm:w-auto w-full mt-8 text-base justify-between focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 focus:outline-none hover:bg-gray-700 font-medium leading-none text-white py-4 px-8 bg-gray-800 flex items-center">
                  Explore
                  <div className="ml-2 mt-0.5">
                    <svg
                      className="fill-stroke"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.33325 8H12.6666"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10 10.6667L12.6667 8"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10 5.33301L12.6667 7.99967"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Designedby;
