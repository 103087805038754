import { Disclosure } from "@headlessui/react";

import { MenuIcon, XIcon } from "@heroicons/react/outline";

import ImageOne from "../assests/logo1.png";

import { Link } from "react-router-dom";

import logo from "../assests/logosequro.png";

const navigation = [
  { name: "Home", href: "/home", current: false },

  { name: "Products & Services", href: "/services", current: false },

  { name: "Hardware", href: "/hardware-products", current: false },

  { name: "About Us", href: "/about_us", current: false },

  { name: "Career", href: "/career_portal", current: false },

  { name: "Contact Us", href: "/contact-us", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Header() {
  const handleFRMClick = () => {
     window.open("https://sequrotechnologies.com/contact-us", );
  };

  return (
    <>
      <div className="min-h-full z-10 sticky top-0">
        <Disclosure as="nav" className="bg-blue-800">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                  <div className="flex items-center">
                    <Link
                      to="/"
                      className="flex-shrink-0 flex items-center space-x-2"
                    >
                      <img
                        className="h-10 w-10"
                        src={ImageOne}
                        alt="Your Company"
                      />

                      <img
                        className="h-10 w-30 bg-white rounded-md px-1"
                        src={logo}
                        alt="Your Company"
                      />
                    </Link>
                  </div>

                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            item.current
                              ? "bg-gray-900 text-white"
                              : "text-gray-100 hover:bg-gray-800 hover:text-white",

                            "px-3 py-2 rounded-md text-sm font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </Link>
                      ))}

                      <button
                        className={classNames(
                          "px-3 py-2 rounded-md text-sm font-medium text-white",

                          "hover:bg-gray-800"
                        )}
                        onClick={handleFRMClick}
                      >
                        FRM Service
                      </button>
                    </div>
                  </div>

                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}

                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-900 p-2 text-gray-100 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="sr-only">Open main menu</span>

                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        item.current
                          ? "bg-gray-900 text-white"
                          : "text-gray-300 hover:bg-gray-700 hover:text-white",

                        "block px-3 py-2 rounded-md text-base font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </Link>
                  ))}

                  <button
                    className={classNames(
                      "block px-3 py-2 rounded-md text-base font-medium text-white",

                      "hover:bg-gray-700"
                    )}
                    onClick={handleFRMClick}
                  >
                    FRM Service
                  </button>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </>
  );
}
