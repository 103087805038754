import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  ArrowCircleRightIcon,
  BadgeCheckIcon,
  ExternalLinkIcon,
} from "@heroicons/react/solid";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import trunky from "../assests/Transaction.gif";
// import mfa from "../assests/herosss.svg";
import videoFileFRM from "../assests/video/frm.mp4";
import videoAcntMgnmt from "../assests/video/AccountManagement.mp4";
import videoFileMulticurrency from "../assests/video/multicurrency.mp4";
import videoFilePuniverse from "../assests/video/productuniverse.mp4";
import videoFileRevolving from "../assests/video/revolving.mp4";
import videoFilePhygital from "../assests/video/phygital.mp4";
import txn from "../assests/mmmmmm.gif";
import pugins from "../assests/plugins.gif";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
//import images here
import pay from "../assests/atm.gif";
import pos from "../assests/pos.gif";
import retail from "../assests/www.sequrotechnologies.com.svg";
import Heading from "../utils/Heading";

const banners = [pay, retail, pos];

const Services = () => {
  const [showMoreMulticurncy, setShowMoreMulticurncy] = useState(false);
  const [showMoreRevlvng, setShowMoreRevlvng] = useState(false);
  const [showMoreFrm, setShowMoreFrm] = useState(false);
  const [showMoreAcc, setShowMoreAcc] = useState(false);
  const [showMorePhygital, setShowMorePhygital] = useState(false);
  const { slug } = useParams();
  console.log(slug);
  const handleToggleforMulticurncy = () => {
    setShowMoreMulticurncy(!showMoreMulticurncy);
  };
  const handleToggleforRevlvng = () => {
    setShowMoreRevlvng(!showMoreRevlvng);
  };
  const handleToggleforFRM = () => {
    setShowMoreFrm(!showMoreFrm);
  };
  const handleToggleforAcc = () => {
    setShowMoreAcc(!showMoreAcc);
  };
  const handleToggleforPhygital = () => {
    setShowMorePhygital(!showMorePhygital);
  };

  return (
    <>
      <Heading
        title={`Services - Sequro Technologies`}
        description={`Sequro is a leading IT company in India, providing a wide range of
              IT services and solutions to clients across the globe. We are
              committed to providing the best IT services to our clients and
              partners.`}
      />

      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={false}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <div className=" flex w-full overflow-hidden"  >
          {banners.map((el, i) => (
            <SwiperSlide key={uuidv4()}>
              <Link
                to="/contact-us"
                className="flex justify-center items-center hover:text-blue-600 border-blue-400 rounded-md"
              >
                <img
              onMouseDown={(e)=>e.preventDefault()} onContextMenu={(e)=>e.preventDefault()}
                  draggable="false"
                  className="h-32 w-full sm:h-96 md:h-128 object-fill  sm:object-cover"
                  src={el}
                  alt="banner"
                  key={i}
                />
              </Link>
            </SwiperSlide>
          ))}
        </div>
      </Swiper>

      <div className="mx-auto container w-full  px-2 mt-1 md:pt-5 " onMouseDown={(e)=>e.preventDefault()} onContextMenu={(e)=>e.preventDefault()}>
        <video loop autoPlay muted className="w-full h-64 md:h-128 " >
          <source src={videoFilePuniverse} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div onMouseDown={(e)=>e.preventDefault()} onContextMenu={(e)=>e.preventDefault()}>
        <h1 className="flex justify-center items-center uppercase text-2xl font-semibold md:text-4xl md:font-bold">
          Products
        </h1>
        <div class="grid  grid-cols-1 md:grid-cols-2 gap-4 p-2 md:p-4">
          <div className="m-2 border border-gray-300 sm:border-gray-100 rounded-md">
            <div className="py-2 px-2 w-full flex justify-between bg-blue-900">
              <p className="text-sm text-white font-semibold tracking-wide">
                TranEco®Switch Unified Payments Platform
              </p>
            </div>
            <div className="bg-white px-2 py-6  text-sm">
              EFT Switch is a Transaction Processing Engine and supports
              End-to-End Merchant Ecosystem used for the acquiring Busine:
              Proposition e.g. POS, UPI, BQR, Micro ATM, PG and also ATM,
              Recycler and issuing platform as well EFT Switch Solution and
              services enables link between the multiple customer touch points
              to the institution’s back-office system. It enhances customer
              experience and operational efficiencies.
              <div className="w-full mt-4  text-blue-700 justify-end flex items-center cursor-pointer">
                <Link to={`/contact-us`}>know more....</Link>
              </div>
              <div className="h-5 w-2" />
            </div>
            
          </div>
        
          <div className="m-2 border border-gray-300 sm:border-gray-100 rounded-md">
            <div className="py-2 px-4 w-full flex justify-between bg-blue-900">
              <p className="text-sm text-white font-semibold tracking-wide">
                TranEco®Payment Gateway ( Offered as License product)
              </p>
            </div>
            <p className="text-gray-900 text-sm px-2 py-6">
              BasisPay provides a digital platform for your payments while
              showing you ways to increase your revenue in online transactions .
              Providing quality payment solutions to bridge the gap between
              expectations and the reality of digital transactions. Form
              agricultural to industrialists. vegetable venders to supermarket
              owners, auto-drivers to airlines-every person can beniefit by
              going digital.
            </p>
            <div className="w-full mt-4 text-blue-700 justify-end flex items-center cursor-pointer">
              <Link to={`/contact-us`}>know more....</Link>
            </div>
          </div>
          </div>
          <div class="grid  grid-cols-1 md:grid-cols-2 gap-4 p-2 md:p-4">
          <div className="m-2 border border-gray-300 sm:border-gray-100 rounded-md">
            <div className="py-2 px-4 w-full flex justify-between bg-blue-900">
              <p className="text-sm text-white font-semibold tracking-wide">
                TranEco®Card Management System + Issuing Switch Platform
              </p>
            </div>
            <div className="bg-white px-3 text-sm   py-4 ">
              <p className="text-gray-700  ">
                Manage your cards and switch platform with our easy to use
                interface. We have you covered with our 24/7 support.
              </p>
            </div>
            <div className="w-full mt-4 text-blue-700 justify-end text-base flex items-center cursor-pointer">
              <Link to={`/contact-us`}>know more....</Link>
            </div>
          </div>
          <div className="m-2 border border-gray-300 sm:border-gray-100 rounded-md">
            <div className="py-2 px-4 w-full flex justify-between bg-blue-900">
              <p className="text-sm text-white font-semibold tracking-wide">
                TranEco®Multi Factor Authentication
              </p>
            </div>
            <div className="bg-white  text-sm  lg:px-6 py-4 ">
              <p className="text-gray-700 text-base  mt-2">
                Traditional passwords aren’t secure enough anymore. From simple
                relaying and spraying attacks to the more sophisticated threats
                of spear-phishing and pharming, hackers have developed countless
                tried and tested methods of stealing credentials and gaining
                unauthorized access to private accounts. In March this year,
                Microsoft engineers said that 99.9% of the account compromise
                incidents they deal with could have been blocked by a
                multi-factor authentication (MFA) solution.
              </p>
              <div className="w-full mt-4 text-blue-700 justify-end flex items-center cursor-pointer">
                <Link to={`/contact-us`}>know more....</Link>
              </div>
            </div>
          </div>
          </div>
          <div id="frmid" className="m-2 border border-gray-300 sm:border-gray-100 rounded-md">
            <div className="py-2 px-4 w-full flex justify-between bg-blue-900">
              <p className="text-sm text-white font-semibold tracking-wide">
                TranEco®From Fraud & Risk Management Platform
              </p>
            </div>
            <div className="mt-4" onMouseDown={(e)=>e.preventDefault()} onContextMenu={(e)=>e.preventDefault()}>
              <video loop autoPlay muted className="w-full h-128" >
                <source src={videoFileFRM} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="bg-white  text-sm">
              {showMoreFrm && (
                <div className="mt-2">
                  <div className="bg-white  text-sm   ">
                    <p className="text-gray-700 text-base  ">
                      Real Time Rule-based Engine Application outlines on below
                      principles, &nbsp;{" "}
                    </p>
                    <p className=" flex  my-2">
                      <ArrowCircleRightIcon className="w-5 h-5 text-blue-500" />
                      <span className="ml-2">Prevention.</span>
                    </p>
                    <p className=" flex  my-2">
                      <ArrowCircleRightIcon className="w-5 h-5 text-blue-500" />
                      <span className="ml-2">Fraud Risk Detection</span>
                    </p>
                    <p className=" flex  my-2">
                      <ArrowCircleRightIcon className="w-5 h-5 text-blue-500" />
                      <span className="ml-2">
                        Monitoring, Reporting & Control
                      </span>
                    </p>
                  </div>
                </div>
              )}

              <div
                className="w-full mt-4 text-blue-700 justify-end flex items-center cursor-pointer"
                onClick={handleToggleforFRM}
              >
                <span>{showMoreFrm ? "Show less" : "Know more...."}</span>
              </div>
            </div>
          
            </div>
            <div className="m-2 border border-gray-300 rounded-md">
            <div className="py-2 px-4 w-full flex justify-between bg-blue-900">
              <p className="text-sm text-white font-semibold tracking-wide">
                TranEco®Multi-Currency Host (For Pre-paid Card Processing)
              </p>
            </div>
            <div className="mt-4" onMouseDown={(e)=>e.preventDefault()} onContextMenu={(e)=>e.preventDefault()}>
              <video loop autoPlay muted className="w-full h-128 ">
                <source src={videoFileMulticurrency} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="bg-white px-3 py-4">
              <div className="bg-white text-sm">
                {showMoreMulticurncy && (
                  <div className="mt-2">
                    <p className="text-gray-700 text-base">
                      An innovative platform to support Multi-currency enabled
                      Card transaction processing supporting the LRS
                      (Liberalised Remittance Scheme) as per the law of land.
                      The platform supports the following features:&nbsp;{" "}
                    </p>
                    <p className="flex my-2">
                      <ArrowCircleRightIcon className="w-5 h-5 text-blue-500" />
                      <span className="ml-2">
                        Currency management to host currencies applicable for
                        transaction.
                      </span>
                    </p>
                    <p className="flex my-2">
                      <ArrowCircleRightIcon className="w-5 h-5 text-blue-500" />
                      <span className="ml-2">
                        Currency Exchange rate management to define buy/sell
                        rate as per the Exchange house
                      </span>
                    </p>
                    <p className="flex my-2">
                      <ArrowCircleRightIcon className="w-5 h-5 text-blue-500" />
                      <span className="ml-2">
                        Currency wallet priority management as per Card holders
                        choice
                      </span>
                    </p>
                    <p className="flex my-2">
                      <ArrowCircleRightIcon className="w-5 h-5 text-blue-500" />
                      <span className="ml-2">
                        LRS compatible with TCS supported based on the
                        compliance requirement of regulators
                      </span>
                    </p>
                    <p className="flex my-2">
                      <ArrowCircleRightIcon className="w-5 h-5 text-blue-500" />
                      <span className="ml-2">
                        Auto Sweep-In/Sweep-Out during real-time transactions to
                        move money between currencies as per the Exchange rates
                      </span>
                    </p>
                    <p className="flex my-2">
                      <ArrowCircleRightIcon className="w-5 h-5 text-blue-500" />
                      <span className="ml-2">
                        Dual Accounting frame-work to track every money movement
                        of transaction and wallet currencies
                      </span>
                    </p>
                    <p className="flex my-2">
                      <ArrowCircleRightIcon className="w-5 h-5 text-blue-500" />
                      <span className="ml-2">
                        Backed with Fee module including Taxation
                      </span>
                    </p>
                    <p className="flex my-2">
                      <ArrowCircleRightIcon className="w-5 h-5 text-blue-500" />
                      <span className="ml-2">
                        Backed with General Ledegers of Currencies for tracking
                        and monitoring{" "}
                      </span>
                    </p>
                    <p className="flex my-2">
                      <ArrowCircleRightIcon className="w-5 h-5 text-blue-500" />
                      <span className="ml-2">
                        Compatible with Bank integration requirement for wallet
                        topup and Escrow management{" "}
                      </span>
                    </p>
                  </div>
                )}

                <div
                  className="w-full mt-4 text-blue-700 justify-end flex items-center cursor-pointer"
                  onClick={handleToggleforMulticurncy}
                >
                  <span>
                    {showMoreMulticurncy ? "Show less" : "Know more...."}
                  </span>
                </div>
              </div>
            </div>
          </div>
        
          
          

          <div className="m-2 border border-gray-300 sm:border-gray-100 rounded-md">
            <div className="py-2 px-4 w-full flex justify-between bg-blue-900">
              <p className="text-sm text-white font-semibold tracking-wide">
                TranEco ®@Account Management System (Platform)
              </p>
            </div>
            <div className="mt-4" onMouseDown={(e)=>e.preventDefault()} onContextMenu={(e)=>e.preventDefault()}>
              <video loop autoPlay muted className="w-full h-128">
                <source src={videoAcntMgnmt} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="bg-white  text-sm">
              {showMoreAcc && (
                <div className="mt-2">
                  <div className="bg-white  text-sm   ">
                    <p className="text-gray-700 text-base  ">
                      Account Management System (AMS) and Accounting Platform of
                      Sequro with built-in Dual Accounting Framework The
                      platform provides options to create Customer Accounts with
                      various features and functionalities that are required for
                      a specific Account to operate A Cust_ID based linked
                      Accounts to have single Cust_ID for a Customer with
                      multiple type of accounts linked Additionally, the AMS is
                      tightly coupled with General Ledgers which plays the
                      critical role in controlling the Chart of Accounts The AMS
                      platform also provides list of APIs from Onboarding until
                      transaction fulfilment with in-house and 3rd party systems
                      integrated as per the Business requirements. , &nbsp;{" "}
                    </p>
                  </div>
                </div>
              )}

              <div
                className="w-full mt-4 text-blue-700 justify-end flex items-center cursor-pointer"
                onClick={handleToggleforAcc}
              >
                <span>{showMoreAcc ? "Show less" : "Know more...."}</span>
              </div>
            </div>
          </div>

        
          <div className="m-2 border border-gray-300 sm:border-gray-100 rounded-md">
            <div className="py-2 px-4 w-full flex justify-between bg-blue-900">
              <p className="text-sm text-white font-semibold tracking-wide">
                TranEco®Revolving Credit Host (Credit Card Processing)
              </p>
            </div>
            <div className="bg-white px-3 py-4">
              <div className="mt-4" onMouseDown={(e)=>e.preventDefault()} onContextMenu={(e)=>e.preventDefault()}>
                <video loop autoPlay muted className="w-full h-128">
                  <source src={videoFileRevolving} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="bg-white text-sm">
                {showMoreRevlvng && (
                  <div className="mt-2">
                    <p className="text-gray-700 text-base">
                      Revolving Credit processing platform is a innovative
                      platform designed by Sequro to support the Credit Card
                      processing requirements. The platform caters to all the
                      features required in processing the Revolving credit
                      functionality. Some of the salient features as follows
                    </p>

                    <p className="flex my-2">
                      <ArrowCircleRightIcon className="w-5 h-5 text-blue-500" />
                      <span className="ml-2">
                        Manages life-cycle of Revolving Credit for Card holder .
                      </span>
                    </p>
                    <p className="flex my-2">
                      <ArrowCircleRightIcon className="w-5 h-5 text-blue-500" />
                      <span className="ml-2">
                        Differentiates between Revolver and Transactor based on
                        the transaction behavioral pattern
                      </span>
                    </p>
                    <p className="flex my-2">
                      <ArrowCircleRightIcon className="w-5 h-5 text-blue-500" />
                      <span className="ml-2">
                        Tracks the respective due dates based on the grace
                        period processing
                      </span>
                    </p>
                    <p className="flex my-2">
                      <ArrowCircleRightIcon className="w-5 h-5 text-blue-500" />
                      <span className="ml-2">
                        Tracks limits based on assigned versus available
                      </span>
                    </p>
                    <p className="flex my-2">
                      <ArrowCircleRightIcon className="w-5 h-5 text-blue-500" />
                      <span className="ml-2">
                        Ability to define grace period at MCC level and for
                        specific card program
                      </span>
                    </p>
                    <p className="flex my-2">
                      <ArrowCircleRightIcon className="w-5 h-5 text-blue-500" />
                      <span className="ml-2">
                        Performs Billing cycle process, generate and distribute
                        bills on the Billing cycle date
                      </span>
                    </p>
                    <p className="flex my-2">
                      <ArrowCircleRightIcon className="w-5 h-5 text-blue-500" />
                      <span className="ml-2">
                        Calculation of daily interest based on the minimum
                        amount due payment
                      </span>
                    </p>
                    <p className="flex my-2">
                      <ArrowCircleRightIcon className="w-5 h-5 text-blue-500" />
                      <span className="ml-2">
                        Fees and Charges management with appropriate systemic
                        posting{" "}
                      </span>
                    </p>
                    <p className="flex my-2">
                      <ArrowCircleRightIcon className="w-5 h-5 text-blue-500" />
                      <span className="ml-2">
                        Full fledged General Ledger processing to track
                        transactions and other charges{" "}
                      </span>
                    </p>
                    <p className="flex my-2">
                      <ArrowCircleRightIcon className="w-5 h-5 text-blue-500" />
                      <span className="ml-2">
                        Delinquency management for unpaid dues at Card holder
                        level{" "}
                      </span>
                    </p>
                  </div>
                )}

                <div
                  className="w-full mt-4 text-blue-700 justify-end flex items-center cursor-pointer"
                  onClick={handleToggleforRevlvng}
                >
                  <span>{showMoreRevlvng ? "Show less" : "Know more...."}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="m-2 border border-gray-300 sm:border-gray-100 rounded-md">
            <div className="py-2 px-4 w-full flex justify-between bg-blue-900">
              <p className="text-sm text-white font-semibold tracking-wide">
              TranEco®Retail Solution (Phygital Stores)
              </p>
            </div>
            <div className="bg-white px-3 py-4">
              <div className="mt-4" onMouseDown={(e)=>e.preventDefault()} onContextMenu={(e)=>e.preventDefault()}>
                <video loop autoPlay muted className="w-full h-128">
                  <source src={videoFilePhygital} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="bg-white text-sm">
                {showMorePhygital && (
                  <div className="mt-2">
                    <p className="text-gray-700 text-base">
                    An innovative single platform to allow access to financial
                products and services in a more fair, efficient way to the
                Merchants, Borrowers and Lenders. Innovation at the core of the
                design and focus on pricing & delivery of products and services.
                    </p>
                  </div>
                )}

                <div
                  className="w-full mt-4 text-blue-700 justify-end flex items-center cursor-pointer"
                  onClick={handleToggleforPhygital}
                >
                  <span>{showMorePhygital ? "Show less" : "Know more...."}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="grid  grid-cols-1 md:grid-cols-2 gap-4 p-2 md:p-4">
          <div className="m-2">
            <div className="py-2 px-4 w-full flex justify-between bg-blue-900">
              <p className="text-sm text-white font-semibold tracking-wide">
                TranEco®PCS Product Cross Sell
              </p>
            </div>
            <div className="bg-white  text-sm  lg:px-6 py-4 ">
              <p className="text-gray-700 text-base  mt-2">
                Product Cross Sell (PCS), a centralised platform to support Risk
                and Reward program with 360 degree view based on the transaction
                pattern on various channels and tracking customer behaviour.
                Then platfrom supports synchronised and asynchronised
                communication option whereby a compaign can be sent as a
                response for event or a notification. The PCS platform is a rule
                based engine.
              </p>
              <div className="w-full mt-4 text-blue-700 justify-end flex items-center cursor-pointer">
                <Link to={`/contact-us`}>know more....</Link>
              </div>
            </div>
          </div>
        
          <div className="m-2 border border-gray-300 sm:border-gray-100 rounded-md">
            <div className="py-2 px-4 w-full flex justify-between bg-blue-900">
              <p className="text-sm text-white font-semibold tracking-wide">
                TranEco®Process Automation Operational Efficiency
              </p>
            </div>
            <div className="bg-white  text-sm  lg:px-6 py-4 ">
              <p className="text-gray-700 text-base  mt-2">
                Ability to automate manual processes and contribute in
                increasing opertional efficiency. Able to consolidate multiple
                data sets under various form factors into one processing engine
                (digital or manual) and providing solutions such as rule based
                validation, time bound analysis thus compensating to the manual
                process and reducing friction.
              </p>
              <div className="w-full mt-4 text-blue-700 justify-end flex items-center cursor-pointer">
                <Link to={`/contact-us`}>know more....</Link>
              </div>
            </div>
          </div>
        
          <div className="m-2 border border-gray-300 sm:border-gray-100 rounded-md">
            <div className="py-2 px-4 w-full flex justify-between bg-blue-900">
              <p className="text-sm text-white font-semibold tracking-wide">
                QR SOUND BOX
              </p>
            </div>
            <div className="bg-white  text-sm  lg:px-6 py-4 ">
              <div>
                <div>
                  <p className=" flex  my-2">
                    <ArrowCircleRightIcon className="w-5 h-5 text-blue-500" />
                    Last mile connectivity solution &nbsp;&nbsp;
                    <ArrowCircleRightIcon className="w-5 h-5 text-blue-500" />
                    Agnostic to any Payment system at the backend
                  </p>
                </div>
                <div>
                  <p className=" flex  my-2">
                    <ArrowCircleRightIcon className="w-5 h-5 text-blue-500" />
                    Real-time status of transaction&nbsp;
                    <ArrowCircleRightIcon className="w-5 h-5 text-blue-500" />
                    Multi-tenant platform
                  </p>
                </div>
                <div>
                  <p className=" flex  my-2">
                    <ArrowCircleRightIcon className="w-5 h-5 text-blue-500" />
                    Monitoring and Tracking option
                  </p>
                </div>
                <div>
                  <p className=" flex  my-2">
                    <ArrowCircleRightIcon className="w-5 h-5 text-blue-500" />
                    Ability to perform device health check such as battery and
                    connectivity
                  </p>
                </div>
                <div>
                  <p className=" flex  my-2">
                    <ArrowCircleRightIcon className="w-5 h-5 text-blue-500" />
                    Multi-lingual support to place choice of language based on
                    state
                  </p>
                </div>
                <div>
                  <p className=" flex  my-2">
                    <ArrowCircleRightIcon className="w-5 h-5 text-blue-500" />
                    Offering contains vChoice of Sound box vPlatform access with
                    required configuration managed Access to your own base
                  </p>
                </div>
                <div>
                  <p className=" flex  my-2">
                    <ArrowCircleRightIcon className="w-5 h-5 text-blue-500" />
                    24x7 availability.
                  </p>
                </div>
              </div>
              <div className="w-full mt-4 text-blue-700 justify-end flex items-center cursor-pointer">
                <Link to={`/contact-us`}>know more....</Link>
              </div>
            </div>
          </div>
       
        <div className="m-2 border border-gray-300 sm:border-gray-100 rounded-md">
          <div className="py-2 px-4 w-full flex justify-between  bg-blue-900">
            <p className="text-sm text-white font-semibold tracking-wide">
              TranEco®Recon Reconciliation & Settlement Service
            </p>
          </div>
          <div className="bg-white  text-sm  lg:px-6 py-4 ">
            <p className="text-gray-700 text-base  mt-2">
              Reconciliation is transaction matching application between
              multiple systems which are involved in processing a transaction
              performed by the Customer / Cardholder. Matching of transaction is
              the vital process in Reconciliation to determine whether the money
              leaving an account matches the amount spent, ensuring the two
              values are matched balanced for the transaction performed during
              that period Banks act as issuer and acquirer bank of various
              payment channels facilitated by various financial networks and
              Card to Card transactions and channels.
            </p>
            <div className="w-full mt-4 text-blue-700 justify-end flex items-center cursor-pointer">
              <Link to={`/contact-us`}>know more....</Link>
            </div>
          </div>
        </div>
        </div>
      </div>
      {/* product section end */}

      <div className="my-4 md:mx-6 " onMouseDown={(e)=>e.preventDefault()} onContextMenu={(e)=>e.preventDefault()}>
        <div className="mx-auto container">
          <h1 className="text-3xl text-center my-10 font-sans font-bold">
            CONSULTING & SERVICES
          </h1>
        </div>
        <div className="px-2 ">
          <div className="flex flex-wrap items-center ">
            <div className="md:w-1/2 lg:w-2/3 w-full xl:pr-20 md:pr-6">
              <div className="my-4">
                <h3 className=" text-2xl text-black font-semibold">
                  Turnkey Project
                </h3>
                <h2 className="text-lg ">Hosted / License Model</h2>
              </div>
              <div className="py-2">
                <p className="text-base lg:text-lg f-f-r flex leading-7 md:leading-10">
                  <BadgeCheckIcon className="h-6 text-blue-600" />
                  Transaction
                </p>
                <p className="text-base lg:text-lg f-f-r flex leading-7 md:leading-10">
                  <BadgeCheckIcon className="h-6 text-blue-600" />
                  HRMS
                </p>
                <p className="text-base lg:text-lg f-f-r flex leading-7 md:leading-10">
                  <BadgeCheckIcon className="h-6 text-blue-600" />
                  Network Settlement
                </p>
                <p className="text-base lg:text-lg f-f-r flex leading-7 md:leading-10">
                  <BadgeCheckIcon className="h-6 text-blue-600" /> Risk &
                  Suspect Management
                </p>
                <p className="text-base lg:text-lg f-f-r  flex leading-7 md:leading-10">
                  <BadgeCheckIcon className="h-6 text-blue-600" />
                  Support System Managed Service
                </p>
                <div className=" inline-flex items-center cursor-pointer py-2   ">
                  <Link
                    to="/contact-us"
                    className="flex justify-center items-center hover:text-blue-600 border-blue-400 border rounded-md px-2 py-1 space-x-1"
                  >
                    <ExternalLinkIcon className=" h-4 " />
                    <h3 className="f-f-r text-sm uppercase  ">More Info</h3>
                  </Link>
                </div>
              </div>
            </div>
            <div className="lg:w-1/3 md:w-1/2 w-full relative h-96 flex items-end justify-center  p-2 md:p-4 ">
              <img
                className="absolute w-full h-full inset-0 object-contain rounded-md "
                src={trunky}
                alt="loading..."
              />
            </div>
          </div>
        </div>
      </div>

      <div className="pb-32 pt-16" onMouseDown={(e)=>e.preventDefault()} onContextMenu={(e)=>e.preventDefault()}>
        <div className="mx-auto">
          <div className="flex flex-wrap flex-row-reverse items-center">
            <div className="md:w-1/2 lg:w-2/3 w-full lg:pl-20 md:pl-10 sm:pl-0 pl-0">
              <div className="py-2 text-color">
                <h1 className="text-2xl lg:text-6xl md:leading-snug tracking-tighter f-f-l font-black">
                  Selective Implementation
                </h1>
                <h2 className="text-lg lg:text-3xl lg:leading-7 md:leading-10 f-f-r py-4 md:py-8">
                  Custom Solutions
                </h2>
                <div className=" inline-flex items-center cursor-pointer py-2   ">
                  <Link
                    to="/contact-us"
                    className="flex justify-center items-center hover:text-blue-600 border-blue-400 border rounded-md px-2 py-1 space-x-1"
                  >
                    <ExternalLinkIcon className=" h-4 " />
                    <h3 className="f-f-r text-sm uppercase  ">More Info</h3>
                  </Link>
                </div>
              </div>
            </div>
            <div className="lg:w-1/3 md:w-1/2 w-full relative h-96 flex items-end justify-center p-2 md:p-4">
              <img
                className="absolute w-full h-full inset-0 object-contain rounded-md"
                src={pugins}
                alt="loading ..."
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex my-4 flex-col md:flex-row md:mx-6 whitespace-nowrap justify-between items-center " onMouseDown={(e)=>e.preventDefault()} onContextMenu={(e)=>e.preventDefault()}>
        <div className="py-2 text-color px-2">
          <h1 className="text-3xl md:text-4xl  font-semibold text-center">
            Independent Services
          </h1>
          <h2 className="text-lg ">Services as Offerings</h2>

          <div className=" inline-flex items-center cursor-pointer py-2   ">
            <div className="flex-flex-col">
              <p className="text-base lg:text-lg f-f-r  flex leading-7 md:leading-10 ">
                <BadgeCheckIcon className="h-6 text-blue-600" /> Card Management
                As A Service
              </p>
              <p className="text-base lg:text-lg f-f-r  flex leading-7 md:leading-10">
                <BadgeCheckIcon className="h-6 text-blue-600" />
                Multi Factor Authentication
              </p>
              <p className="text-base lg:text-lg f-f-r  flex leading-7 md:leading-10">
                <BadgeCheckIcon className="h-6 text-blue-600" />
                Fraud & Risk Management Platform
              </p>
              <p className="text-base lg:text-lg f-f-r  flex leading-7 md:leading-10">
                <BadgeCheckIcon className="h-6 text-blue-600" />
                Merchant Management System
              </p>
              <p className="text-base lg:text-lg f-f-r  flex leading-7 md:leading-10">
                <BadgeCheckIcon className="h-6 text-blue-600" />
                Payment Hold/Release Management
              </p>
              <p className="text-base lg:text-lg f-f-r  flex leading-7 md:leading-10">
                <BadgeCheckIcon className="h-6 text-blue-600" />
                Suspect Tracking As A Service
              </p>
              <p className="text-base lg:text-lg f-f-r  flex leading-7 md:leading-10">
                <BadgeCheckIcon className="h-6 text-blue-600" />
                Merchant Portal
              </p>
              <p className="text-base lg:text-lg f-f-r  flex leading-7 md:leading-10">
                <BadgeCheckIcon className="h-6 text-blue-600" />
                Risk As A Service
              </p>
              <p className="text-base lg:text-lg f-f-r  flex leading-7 md:leading-10">
                <BadgeCheckIcon className="h-6 text-blue-600" />
                HSM As A Service
              </p>
              <div className=" inline-flex items-center cursor-pointer py-2   ">
                <Link
                  to="/contact-us"
                  className="flex justify-center items-center hover:text-blue-600 border-blue-400 border rounded-md px-2 py-1 space-x-1"
                >
                  <ExternalLinkIcon className=" h-4 " />
                  <h3 className="f-f-r text-sm uppercase  ">More Info</h3>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="h-88 p-4 ">
          <img className=" object-contain  " src={txn} alt="loading ..." />
        </div>
      </div>
    </>
  );
};

export default Services;
