import Hero from "../assests/herosection.gif";
import img1 from "../assests/icon1.png";
import img2 from "../assests/icon2.png";
import { PencilAltIcon } from "@heroicons/react/solid";
import img3 from "../assests/icon3.png";
import twitter from "../assests/twitter.png";
import linkdin from "../assests/linkedin.png";
import img4 from "../assests/icon4.png";
import whychooseus from "../assests/whychooseus.png";
import img5 from "../assests/icon5.png";
// import techvideo from "../assests/techvideo.mp4";
import alisir from "../assests/alisir.png";
import sachinsir from "../assests/sachinsir.png";
import tianyu from "../assests/tianioimage.png";
import npci from "../assests/npciimage.png";
import futuretech from "../assests/FutureTech.png";
import img6 from "../assests/icon6.png";
import tyche from "../assests/tyche.png";
import { Link } from "react-router-dom";
import ContactForm from "../page/ContactForm";
import { OurTechStack } from "../page/OurTechStack";
import webwerks from "../assests/webwerks.png";
import Heading from "../utils/Heading";
export default function LandingPage() {
  const products = [
    { id: 1, name: "ncpi", to: "https://npci.org.in/", image: npci },
    { id: 2, name: "tyche", to: "https://tychepayment.com/", image: tyche },
    { id: 3, name: "tianyu", to: "https://www.whty.com/", image: tianyu },
    {
      id: 4,
      name: "futuretech",
      to: "https://ftei.com/partners",
      image: futuretech,
    },
    {
      id: 5,
      name: "webwerks",
      to: "https://webwerks.in/",
      image: webwerks,
    },
  ];
  return (
    <>
      <Heading
        title={"Home - Sequro Technologies"}
        description={`Sequro is a leading IT company in India, providing a wide range of
              IT services and solutions to clients across the globe. We are
              committed to providing the best IT services to our clients and
              partners. We have a team of highly skilled and experienced
              professionals who are dedicated to providing the best IT services
              to our clients. We have a team of highly skilled and experienced
              professionals who are dedicated to providing the best IT services
              to our clients.`}
      />
      <div className="relative flex sm:flex-row flex-col bg-white overflow-hidden scroll-smooth hover:scroll-auto" onMouseDown={(e)=>e.preventDefault()} onContextMenu={(e)=>e.preventDefault()}>
        <div className="max-w-full mx-auto">
          <div className="relative  pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <main className="mt-10 mx-auto max-w-full px-4 sm:mt-12 sm:px-12 md:mt-16 lg:mt-20  xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h1 className="whitespace-nowrap text-2xl  space-y-4 md:text-6xl sm:text-3x1 sm:font-black capitalize leading-4 text-opacity-100 font-bold ...">
                  <span>Sequro Technologies</span>
                  <span className="block  text-black">Pvt. Ltd.</span>
                </h1>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0 font-sans">
                  Focusing on Payments Eco-System to address Gaps and Enhance
                  Digitization. Mission is to reach millions to enable Payments
                  and Digitization. & software engineering services.
                </p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  <div className="rounded-md ">
                    <button
                      type="button"
                      className="bg-gradient-to-r w-28 h-12  rounded-md from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500 ..."
                    >
                      {" "}
                      <Link onClick={ContactForm} to={`/contact-us`}>
                        Contact Us
                      </Link>
                    </button>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
        <div className=" px-2 sm:px-4">
          <img className="" src={Hero} alt="" />
        </div>
      </div>
      <div>
        <div className=" py-4 px-4 " onMouseDown={(e)=>e.preventDefault()} onContextMenu={(e)=>e.preventDefault()}>
          <div className=" w-full">
            <div className="mb-2 text-center">
              <h2 className="text-4xl font-bold text-blue-900">ABOUT US</h2>
            </div>
            <p className="text-lg  text-center text-gray-600 mt-6  font-sans ">
              Sequro continously explores the external environment to understand
              the emerging technologies, regulatory and business requirements,
              etc. to upgrade products and meet the requirements of our clients.
              We believe in providing quality solutions at competitive prices.
              We have bouquet of solutions, Which cater to the needs of Banking
              & Payments, Non-BFSI, ERP, FinTech and other.
            </p>
          </div>

          {/* <div className="lg:block hidden   ">
            <video
              src={techvideo}
              muted
              autoPlay
              loop
              className=" min-w-full md:h-96 object-center object-fill my-4  "
            ></video>
          </div> */}

          <div>
            <div className="w-full  px-4 md:px-16 pt-5 " onMouseDown={(e)=>e.preventDefault()} onContextMenu={(e)=>e.preventDefault()}>
              <div className="container mx-auto">
                <div className="lg:flex md:flex sm:flex items-center xl:justify-between flex-wrap md:justify-around sm:justify-around lg:justify-around">
                  <div className="xl:w-1/3 sm:w-3/4 md:w-2/5 relative mt-20 mb-32 sm:mb-24 xl:max-w-sm lg:w-2/5">
                    <div className="rounded-lg overflow-hidden shadow-md bg-white">
                      <div className="absolute -mt-20 w-full flex justify-center">
                        <div className="h-32 w-32">
                          <img
                            src={sachinsir}
                            alt=""
                            className="rounded-full bg-gray-300 object-cover h-full w-full shadow-md"
                          />
                        </div>
                      </div>
                      <div className=" px-2 md:px-6 mt-16">
                        <div className="font-bold text-3xl uppercase text-center pb-1">
                          Sachin Koti
                        </div>
                        <p className="text-blue-800 text-sm text-center font-bold">
                          CEO & MD
                        </p>
                        <p className="text-center text-gray-600 text-base pt-3 font-normal">
                          27&nbsp;<sup>years</sup> of strong experience in
                          setting up various technology setup .....
                        </p>
                        <Link
                          className="text-blue-800 text-center capitalize  flex justify-center items-center my-2  "
                          to={`/about-team#sachinkoti`}
                        >
                          know more
                        </Link>
                        <div className="w-full flex justify-center pt-5 pb-5">
                          <a
                            href="http://www.linkedin.com/in/sachin-koti-pmp"
                            className="mx-5"
                            target="_blank"
                            rel=" noreferrer"
                          >
                            <div>
                              <img src={linkdin} alt="" className="h-6 w-6" />
                            </div>
                          </a>
                          <a href="/" className="mx-5">
                            <div>
                              <img src={twitter} alt="" className="h-6 w-6" />
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="xl:w-1/3 sm:w-3/4 md:w-2/5 relative mt-16 mb-32 sm:mb-24 xl:max-w-sm lg:w-2/5">
                    <div className="rounded overflow-hidden shadow-md bg-white">
                      <div className="absolute -mt-20 w-full flex justify-center">
                        <div className="h-32 w-32 ">
                          <img
                            src={alisir}
                            alt=""
                            className="rounded-full bg-gray-300 object-cover h-full w-full shadow-md"
                          />
                        </div>
                      </div>
                      <div className="px-6 mt-16">
                        <div className="font-bold text-3xl uppercase text-center pb-1">
                          Mir Ameer Ali
                        </div>
                        <p className="text-blue-800 text-sm text-center font-bold">
                          DIRECTOR
                        </p>
                        <p className="text-center text-gray-600 text-base pt-3 font-normal">
                          33&nbsp;<sup>years</sup> of experience in Bank, MNCs ,
                          IT companies .....
                        </p>
                        <Link
                          className="text-blue-800 text-center capitalize  flex justify-center items-center my-2   "
                          to={`/about-team-director`}
                        >
                          know more
                        </Link>
                        <div className="w-full flex justify-center pt-5 pb-5">
                          <div className="w-full flex justify-center pt-5 pb-5">
                            <a
                              href="http://www.linkedin.com/in/mir-ali-ba409631"
                              className="mx-5"
                              target="_blank"
                              rel=" noreferrer"
                            >
                              <div>
                                <img src={linkdin} alt="" className="h-6 w-6" />
                              </div>
                            </a>
                            <a href="/" className="mx-5">
                              <div>
                                <img src={twitter} alt="" className="h-6 w-6" />
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-2xl py-2 px-4 sm:py-2 sm:px-6 lg:max-w-7xl lg:px-8" onMouseDown={(e)=>e.preventDefault()} onContextMenu={(e)=>e.preventDefault()}>
        <h2 className="text-4xl font-bold text-center tracking-tight text-gray-900">
          OUR TRUSTED PARTNER'S
        </h2>
        <div className=" flex flex-warp flex-col md:flex-row justify-center items-center space-y-4 md:space-x-5 mx-auto max-w-2xl py-6 px-2 sm:py-16 sm:px-6 lg:max-w-7xl lg:px-8" onMouseDown={(e)=>e.preventDefault()} onContextMenu={(e)=>e.preventDefault()}>
          {products?.map((product) => (
            <div className="flex justify-center items-center bg-gray-50 shadow ">
              <a
                key={product.id}
                href={product.to}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={product.image}
                  alt={product.name}
                  className=" object-fit object-center h-32 w-64 "
                />
              </a>
            </div>
          ))}
        </div>
      </div>

      <div className="overflow-y-hidden" onMouseDown={(e)=>e.preventDefault()} onContextMenu={(e)=>e.preventDefault()}>
        <div className="xl:mx-auto xl:container  xl:px-20 md:px-6 px-4 py-12">
          <div className="lg:flex items-center justify-center lg:space-x-12 2xl:space-x-6">
            <div className>
              <p className="lg:text-4xl text-3xl font-extrabold leading-9 text-gray-800">
                Why choose us....?
              </p>
              <p className=" flex  my-2">
                <PencilAltIcon className="w-6 h-6 text-blue-500" />
                <span className="ml-2">Industry Experts </span>
              </p>
              <p className=" flex  my-2">
                <PencilAltIcon className="w-6 h-6 text-blue-500" />
                <span className="ml-2">Domain Understanding </span>
              </p>
              <p className=" flex  my-2">
                <PencilAltIcon className="w-6 h-6 text-blue-500" />
                <span className="ml-2">Proven Track Record</span>
              </p>
              <p className=" flex  my-2">
                <PencilAltIcon className="w-6 h-6 text-blue-500" />
                <span className="ml-2">Innovative</span>
              </p>
              <p className=" flex  my-2">
                <PencilAltIcon className="w-6 h-6 text-blue-500" />
                <span className="ml-2">Proven Creativity </span>
              </p>
              <p className=" flex  my-2">
                <PencilAltIcon className="w-6 h-6 text-blue-500" />
                <span className="ml-2">
                  Technology knowhow with good command on latest Technology
                </span>
              </p>
              <p className=" flex  my-2">
                <PencilAltIcon className="w-6 h-6 text-blue-500" />
                <span className="ml-2">
                  Prefered Partners (Always the 1<sup>st</sup> choice)
                </span>
              </p>
              <p className=" flex  my-2">
                <PencilAltIcon className="w-6 h-6 text-blue-500" />
                <span className="ml-2">Solutions Under One Roof</span>
              </p>
              <div className="lg:hidden lg:w-3/5 xl:w-3/5 w-full lg:mt-0 mt-6">
                <img
                  src={whychooseus}
                  alt="ongoing meeting"
                  className="object-cover object-center w-full h-full rounded-lg shadow-md"
                />
              </div>
              <div className="mt-6 md:mt-10 grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-6 lg:mt-6 2xl:mt-12">
                <div className="flex items-center">
                  <div className="w-16 h-16 relative">
                    <div className=" flex-shrink-0 z-20  w-16 h-16 flex items-center justify-center mt-2 mr-3">
                      <img
                        src="https://tuk-cdn.s3.amazonaws.com/can-uploader/feature_6_Svg1.svg"
                        alt="clock"
                      />
                    </div>
                  </div>
                  <div className="flex items-start flex-col ml-6 pt-8">
                    <h2 className="text-lg font-semibold leading-4 text-gray-800">
                      We Are On Time
                    </h2>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="w-16 h-16 relative">
                    <div className=" flex-shrink-0 z-20  w-16 h-16 flex items-center justify-center mt-2 mr-3">
                      <img
                        src="https://tuk-cdn.s3.amazonaws.com/can-uploader/feature_6_Svg2.svg"
                        alt="Friendly"
                      />
                    </div>
                  </div>
                  <div className="flex items-start flex-col ml-6 pt-8">
                    <h2 className="text-lg font-semibold leading-4 text-gray-800">
                      We Create user friendly product
                    </h2>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="w-16 h-16 relative">
                    <div className=" flex-shrink-0 z-20  w-16 h-16 flex items-center justify-center mt-2 mr-3">
                      <img
                        src="https://tuk-cdn.s3.amazonaws.com/can-uploader/feature_6_Svg3.svg"
                        alt="Creative"
                      />
                    </div>
                  </div>
                  <div className="flex items-start flex-col ml-6 pt-8">
                    <h2 className="text-lg font-semibold leading-4 text-gray-800">
                      Creative Ideas
                    </h2>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="w-16 h-16 relative">
                    <div className=" flex-shrink-0 z-20  w-16 h-16 flex items-center justify-center mt-2 mr-3">
                      <img
                        src="https://tuk-cdn.s3.amazonaws.com/can-uploader/feature_6_Svg4.svg"
                        alt="Achievments"
                      />
                    </div>
                  </div>
                  <div className="flex items-start flex-col ml-6 pt-8">
                    <h2 className="text-lg font-semibold leading-4 text-gray-800">
                      We Achieve the defined Goals
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden lg:block rounded-lg lg:w-3/5 xl:w-3/5 w-full lg:mt-0 mt-6">
              <img
                //src="https://i.ibb.co/SKLJ7WX/austin-distel-jp-Hw8ndw-J-Q-unsplash-1.png"
                src={whychooseus}
                alt="ongoing meeting"
                className=" flex  w-full   h-96  rounded-lg"
              />
            </div>
          </div>
        </div>
      </div>
      <OurTechStack />
      <div id="/services" className="mb-8 text-center mt-10 " onMouseDown={(e)=>e.preventDefault()} onContextMenu={(e)=>e.preventDefault()}>
        <h2 className="md:text-4xl text-2xl font-bold text-black">
          SEQURO TECHNOLOGIES DIFFERENTIATORS
        </h2>
        <p className="text-lg text-gray-600">
          Here are our Key Differentiators
        </p>
      </div>

      <div className="lg:grid lg:grid-cols-3  grid gap-8  p-6  my-12 " onMouseDown={(e)=>e.preventDefault()} onContextMenu={(e)=>e.preventDefault()}>
        <div className="p-4 text-gray-800 rounded-lg shadow-md bg-white ">
          <p className="mb-2 text-center text-gray-600  font-extrabold ">
            In House Technology
          </p>
          <div className="flex flex-col items-center justify-center">
            <div className="w-12 h-12 overflow-hidden bg-gray-100 border-2 border-indigo-100 rounded-full">
              <img
                src={img1}
                alt="img"
                className="object-cover object-center w-full h-full"
              />
            </div>
            <h3 className="text-center  font-medium text-gray-800">
              No 3rd party dependency Agile Competent.
            </h3>
          </div>
        </div>
        <div className="p-4 text-gray-800 bg-white  rounded-lg shadow-md hover:bg-white ">
          <p className="mb-2 text-center text-gray-600 font-extrabold  ">
            Competitive and Cost effective
          </p>
          <div className="flex flex-col items-center justify-center">
            <div className="w-12 h-12 overflow-hidden bg-gray-100 border-2 border-indigo-100 rounded-full">
              <img
                src={img2}
                alt="img"
                className="object-cover object-center w-full h-full"
              />
            </div>
            <h3 className="font-bold text-green-800">
              <p className="text-center  font-medium text-gray-800">
                Competitive and Cost effective Solutions Compared to other
                players in the Market
              </p>
            </h3>
          </div>
        </div>
        <div className="p-4 text-gray-800 bg-white  rounded-lg shadow-md hover:bg-white">
          <p className="mb-2 text-center text-gray-600 font-extrabold  ">
            Resolutions
          </p>
          <div className="flex flex-col items-center justify-center">
            <div className="w-12 h-12 overflow-hidden bg-gray-100 border-2 border-indigo-100 rounded-full">
              <img
                src={img4}
                alt="img"
                className="object-cover object-center w-full h-full"
              />
            </div>
            <h3 className="font-bold text-green-800">
              <p className="text-center  font-medium text-gray-800">
                Resolves pain-points Being Innovative
              </p>
            </h3>
          </div>
        </div>
        <div className="p-4 text-gray-800 bg-white rounded-lg shadow-md">
          <p className="mb-2 text-center text-gray-600 font-extrabold  ">
            Operational Effeciency
          </p>
          <div className="flex flex-col items-center justify-center">
            <div className="w-12 h-12 overflow-hidden bg-gray-100 border-2 border-indigo-100 rounded-full">
              <img
                src={img3}
                alt="img"
                className="object-cover object-center w-full h-full"
              />
            </div>
            <h3 className="font-bold text-green-800">
              <p className="text-center  font-medium text-gray-800">
                Increase operational efficiency Managed Service offerings
              </p>
            </h3>
          </div>
        </div>

        <div className="p-4 text-gray-800 bg-white  rounded-lg shadow-md hover:bg-white ...">
          <p className="mb-2 text-center text-gray-600 font-extrabold  ">
            Custom Solutions
          </p>
          <div className="flex flex-col items-center justify-center">
            <div className="w-12 h-12 overflow-hidden bg-gray-100 border-2 border-indigo-100 rounded-full">
              <img
                src={img6}
                alt="img"
                className="object-cover object-center w-full h-full"
              />
            </div>
            <h3 className="font-bold text-green-800">
              <p className="text-center  font-medium text-gray-800">
                Solutions which will help Increase efficiency in various
                Business Scenarios
              </p>
            </h3>
          </div>
        </div>
        <div className="p-4 text-gray-800 hover:bg-white bg-white   rounded-lg shadow-md">
          <p className="mb-2 text-center text-gray-600 font-extrabold ">
            Enabler
          </p>
          <div className="flex flex-col items-center justify-center">
            <div className="w-12 h-12 overflow-hidden bg-gray-100 border-2 border-indigo-100 rounded-full">
              <img
                src={img5}
                alt="img"
                className="object-cover object-center w-full h-full"
              />
            </div>
            <h3 className="font-bold text-blue-800">
              <p className="text-center  font-medium text-gray-800">
                Enabler in all aspects of Payment Solutions
              </p>
            </h3>
          </div>
        </div>
      </div>
    </>
  );
}
