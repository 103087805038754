import React, { useRef } from "react";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import Heading from "../utils/Heading";

const CreerPortal = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_zi3mc4e",
        "template_uytlqxt",
        form.current,
        "pQx28PDfgz9S7HYd7"
      )
      .then(
        () => {
          toast("Details sent Successfull we will connect you shortly!", {
            type: "success",
          });
        },
        (error) => {
          toast.error(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <>
      <div className="flex items-center justify-center">
        <Heading
          title="Career - Sequro Technologies"
          description="Sequro is a leading IT company in India, providing a wide range of
              IT services and solutions to clients across the globe. We are
              committed to providing the best IT services to our clients and
              partners. We have a team of highly skilled and experienced
              professionals who are dedicated to providing the best IT services
              to our clients. We have a team of highly skilled and experienced
              professionals who are dedicated to providing the best IT services
              to our clients."
        />
        {/* <section className="py-24 px-12 bg-gray-50 overflow-hidden">
          <div className="container  mx-auto  rounded-lg">
            <div className="mb-8 text-center">
              <h2 className="text-4xl font-bold ">CAREER DEVELOPMENT</h2>
              <br></br>
              <div className="text-lg text-md text-gray-600">
                {" "}
                <p>
                  We believe that the best way to grow is to learn from the
                  best. That's why we have a dedicated team of career coaches
                  who are available to help you with your career development.
                </p>
              </div>
            </div>
            <div className="bg-gray-100 rounded-md shadow py-8 space-x-4 flex flex-wrap my-6 flex-col items-center justify-center">
              <div className="my-4 text-xl uppercase font-bold">
                <h1>Hiring process</h1>
              </div>
              <div className=" flex justify-between items-center">
                <div className=" h-16 relative md:mt-0 mt-4">
                  <img
                    src="https://i.ibb.co/DwNs7zG/Steps.png"
                    alt="step1"
                    className="w-full h-full"
                  />
                  <div className="absolute w-full flex flex-col px-6 items-center justify-center inset-0 m-0">
                    <p className="w-full text-sm font-medium leading-4 text-white">
                      HR Round
                    </p>
                    <p className="w-full text-xs mt-1 leading-none text-white">
                      Some info about you
                    </p>
                  </div>
                </div>
                <div className=" h-16 relative md:mt-0 mt-4">
                  <img
                    src="https://i.ibb.co/wNZ4nzy/Steps2.png"
                    alt="step2"
                    className="w-full h-full"
                  />
                  <div className="absolute w-full flex flex-col px-6 items-center justify-center inset-0 m-0">
                    <p className="w-full text-sm font-medium leading-4 text-indigo-800">
                      Technical Round
                    </p>
                    <p className="w-full text-xs mt-1 leading-none text-indigo-800">
                      Some info about your skills
                    </p>
                  </div>
                </div>
                <div className=" h-16 relative md:mt-0 mt-4">
                  <img
                    src="https://i.ibb.co/c2k4Gbr/Steps3.png"
                    alt="step3"
                    className="w-full h-full"
                  />
                  <div className="absolute w-full flex flex-col px-6 items-center justify-center inset-0 m-0">
                    <h4 className="w-full text-sm font-medium leading-4 text-gray-700">
                      Managerial Round
                    </h4>
                    <p className="w-full text-xs mt-1 leading-none text-gray-500">
                      Some info about yourself
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className=" my-10 lg:grid lg:grid-cols-3 lg:gap-x-2 grid gap-2  pb-2  ">
              <div className=" sm:pr-4 sm:pl-4 rounded-md bg-gradient-to-r from-teal-400 to-blue-500 hover:from-pink-500 hover:to-orange-500 text-white font-semibold px-4 py-8">
                <p className="mb-2 text-center text-gray-800 font-extrabold ">
                  Backend Developer (JAVA AND NODE JS)
                </p>
                <div className="flex flex-col items-center justify-center">
                  <h3 className="text-center text-gray-800 font-medium ">
                    3+ years of experience
                  </h3>
                </div>
              </div>

              <div className="sm:pr-4 sm:pl-4 rounded-md bg-gradient-to-r from-teal-400 to-blue-500 hover:from-pink-500 hover:to-orange-500 text-white font-semibold px-4 py-8 ">
                <p className="mb-2 text-center text-gray-800 font-extrabold  ">
                  Frontend Developer (React JS AND ANGULAR)
                </p>
                <div className="flex flex-col items-center justify-center">
                  <h3 className="font-bold ">
                    <p className="text-center  text-gray-800 font-medium">
                      1+ years of experience
                    </p>
                  </h3>
                </div>
              </div>
              <div className="p-4 py-8 sm:pr-2 sm:pl-2 rounded-md  bg-gradient-to-r from-teal-400 to-blue-500 hover:from-pink-500 hover:to-orange-500 text-white font-semibold px-4   ">
                <p className="mb-2 text-center text-gray-800 font-extrabold   ">
                  UX Designer
                </p>
                <div className="flex flex-col items-center justify-center">
                  <h3 className="font-bold ">
                    <p className="text-center text-gray-800 font-medium ">
                      2+ years of experience
                    </p>
                  </h3>
                </div>
              </div>
              <div className=" sm:pr-4 sm:pl-4 rounded-md bg-gradient-to-r from-teal-400 to-blue-500 hover:from-pink-500 hover:to-orange-500 text-white font-semibold px-4 py-8">
                <p className="mb-2 text-center text-gray-800 font-extrabold  ">
                  Software Tester (Manual and Automation)
                </p>
                <div className="flex flex-col items-center justify-center">
                  <h3 className="font-bold text-green-800">
                    <p className="text-center  font-medium text-gray-800">
                      2+ years of experience
                    </p>
                  </h3>
                </div>
              </div>
              <div className=" sm:pr-4 sm:pl-4 rounded-md bg-gradient-to-r from-teal-400 to-blue-500 hover:from-pink-500 hover:to-orange-500 text-white font-semibold px-4 py-8">
                <p className="mb-2 text-center text-gray-800 font-extrabold  ">
                  Business Development Analyst
                </p>
                <div className="flex flex-col items-center justify-center">
                  <h3 className="font-bold text-gray-800">
                    <p className="text-center  font-medium text-gray-800">
                      2+ years of experience
                    </p>
                  </h3>
                </div>
              </div>
              <div className=" sm:pr-4 sm:pl-4 rounded-md bg-gradient-to-r from-teal-400 to-blue-500 hover:from-pink-500 hover:to-orange-500 text-white font-semibold px-4 py-8 ">
                <p className="mb-2 text-center text-gray-800 font-extrabold  ">
                  Database Engineer
                </p>
                <div className="flex flex-col items-center justify-center">
                  <h3 className="font-bold text-green-800">
                    <p className="text-center  font-medium text-gray-800">
                      2+ years of experience
                    </p>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </div>
      <div className="flex items-center justify-center ">
        <div className=" w-full px-12">
          <div>
            <div className="px-5 py-4 bg-gray-100 rounded-lg flex items-center justify-between mt-7">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19 9.99999H20C20.2652 9.99999 20.5196 10.1054 20.7071 10.2929C20.8946 10.4804 21 10.7348 21 11V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V11C3 10.7348 3.10536 10.4804 3.29289 10.2929C3.48043 10.1054 3.73478 9.99999 4 9.99999H5V8.99999C5 8.08074 5.18106 7.17049 5.53284 6.32121C5.88463 5.47193 6.40024 4.70026 7.05025 4.05025C7.70026 3.40023 8.47194 2.88462 9.32122 2.53284C10.1705 2.18105 11.0807 1.99999 12 1.99999C12.9193 1.99999 13.8295 2.18105 14.6788 2.53284C15.5281 2.88462 16.2997 3.40023 16.9497 4.05025C17.5998 4.70026 18.1154 5.47193 18.4672 6.32121C18.8189 7.17049 19 8.08074 19 8.99999V9.99999ZM17 9.99999V8.99999C17 7.67391 16.4732 6.40214 15.5355 5.46446C14.5979 4.52678 13.3261 3.99999 12 3.99999C10.6739 3.99999 9.40215 4.52678 8.46447 5.46446C7.52678 6.40214 7 7.67391 7 8.99999V9.99999H17ZM11 14V18H13V14H11Z"
                      fill="#4B5563"
                    />
                  </svg>
                </div>

                <p className="text-sm text-gray-800 pl-3">
                  We take privacy issues seriously. You can be sure that your
                  personal data is securely protected.
                </p>
              </div>
              <button className="md:block hidden focus:outline-none focus:ring-2 focus:ring-gray-700 rounded">
                <svg
                  aria-label="Close this banner"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.8337 5.34166L14.6587 4.16666L10.0003 8.825L5.34199 4.16666L4.16699 5.34166L8.82533 10L4.16699 14.6583L5.34199 15.8333L10.0003 11.175L14.6587 15.8333L15.8337 14.6583L11.1753 10L15.8337 5.34166Z"
                    fill="#79808F"
                  />
                </svg>
              </button>
            </div>
            {/* forms */}

            <div className="md:grid md:grid-cols-1 my-8 md:gap-6">
              <div className="mt-5 md:col-span-2 md:mt-0 ">
                <form
                  ref={form}
                  onSubmit={sendEmail}
                  className=" flex justify-center"
                >
                  <div className="  sm:overflow-hidden sm:rounded-md">
                    <div className="space-y-6  bg-white px-4 py-5 sm:p-6">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            First name
                          </label>
                          <input
                            required
                            type="text"
                            name="first-name"
                            id="first-name"
                            placeholder="first name"
                            autoComplete="given-name"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="last-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Last name
                          </label>
                          <input
                            required
                            type="text"
                            name="last-name"
                            id="last-name"
                            placeholder="last name"
                            autoComplete="family-name"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="email-address"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email address
                          </label>
                          <input
                            required
                            type="email"
                            name="email-address"
                            id="email-address"
                            autoComplete="email"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="country"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Phone Number
                          </label>
                          <input
                            required
                            id="phone"
                            name="phone"
                            type="text"
                            autoComplete="phone"
                            className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="role"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Role
                          </label>
                          <input
                            type="text"
                            name="role"
                            id="role"
                            required
                            placeholder="role"
                            autoComplete="role"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="experience"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Work Experience
                          </label>
                          <input
                            type="text"
                            name="experience"
                            id="experience"
                            autoComplete="experience"
                            required
                            placeholder="fresher/experienced"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>

                        <div className="col-span-6 ">
                          <label
                            htmlFor="resume-link"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Resume Link
                          </label>

                          <input
                            type="text"
                            name="resume-link"
                            id="resume-link"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            placeholder="https://drive.google.com/myresumelink"
                          />
                        </div>
                        <div className="col-span-6 ">
                          <label
                            htmlFor="portfolio-link"
                            className="block text-sm font-medium text-gray-700"
                          >
                            LinkedIn / Portfolio Link
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <input
                              type="text"
                              name="portfolio-link"
                              id="portfolio-link"
                              className="block w-full flex-1  rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              placeholder="https://www.linkedin.com/myprofilelink"
                            />
                          </div>
                        </div>
                        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                          <label
                            htmlFor="city"
                            className="block text-sm font-medium text-gray-700"
                          >
                            City
                          </label>
                          <input
                            type="text"
                            name="city"
                            id="city"
                            autoComplete="address-level2"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                          <label
                            htmlFor="region"
                            className="block text-sm font-medium text-gray-700"
                          >
                            State / Province
                          </label>
                          <input
                            type="text"
                            name="region"
                            id="region"
                            autoComplete="address-level1"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                          <label
                            htmlFor="postal-code"
                            className="block text-sm font-medium text-gray-700"
                          >
                            ZIP / Postal code
                          </label>
                          <input
                            type="text"
                            name="postal-code"
                            id="postal-code"
                            autoComplete="postal-code"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>
                      </div>

                      <div>
                        <label
                          htmlFor="about"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Introduce yourself{" "}
                        </label>
                        <div className="mt-1">
                          <textarea
                            id="about"
                            name="about"
                            rows={3}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            placeholder="in 200 words or less."
                            defaultValue={""}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="px-4 py-3 text-center sm:px-6">
                      <button
                        type="submit"
                        className="inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-6 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreerPortal;
