import React from "react";
import service1 from "../assests/services3.jpg";
import sachin from "../assests/sachinsir.png";
import { ArrowCircleRightIcon } from "@heroicons/react/solid";
const OfferingData = () => {
  return (
    <div className="flex items-center flex-col justify-center w-full  ">
      {/* Card code block start */}
      <div id="sachinkoti" className="bg-white  shadow rounded">
        <div className="relative">
          <img
            className="h-56 shadow  w-full object-cover object-center"
            src={service1}
            alt="loading..."
          />
          <div className="inset-0 m-auto w-24 h-24 absolute bottom-0 -mb-12 xl:ml-10  border-2 shadow border-white rounded-full">
            <img
              className="w-full h-full overflow-hidden object-cover rounded"
              src={sachin}
              alt="loading..."
            />
          </div>
        </div>
        <div className="px-5 xl:px-10 pb-10 py-2">
          <div className="pt-3 xl:pt-5 flex flex-col xl:flex-row items-start xl:items-center justify-between">
            <div className="xl:pr-16 w-full  py-8">
              <div className="text-center xl:text-left mb-3 xl:mb-0 flex flex-col xl:flex-row items-center justify-between xl:justify-start">
                <h2 className="mb-2 xl:mb-0 xl:mr-4 text-3xl uppercase font-semibold  dark:text-gray-900  tracking-normal">
                  Sachin Koti
                </h2>
                <div className="text-sm bg-indigo-700 dark:bg-indigo-600 text-white px-5 py-1 font-normal rounded-full">
                  CEO & MD
                </div>
              </div>
              <p className=" flex  my-5">
                <ArrowCircleRightIcon className="w-6 h-6 text-blue-500" />
                <span className="ml-2">
                  Sachin Koti has been in the industry for 27+ years and have
                  been working at senior roles in the organisations he worked.{" "}
                </span>
              </p>
              <p className=" flex  my-2">
                <ArrowCircleRightIcon className="w-6 h-6 text-blue-500" />
                <span className="ml-2">
                  He is a Techno-functional with rich experience in BFSI and
                  Payments domain and has worked in large organisations such as
                  Nextstep Infotech, NCR, Euronet, OFSS (erstwhile i-Flex),
                  Hitachi Payments (erstwhile Prizm Payments), BTI Payments, and
                  Visa Inc.{" "}
                </span>
              </p>
              <p className=" flex  my-2">
                <ArrowCircleRightIcon className="w-6 h-6 text-blue-500" />
                <span className="ml-2">
                  Last he worked with Visa Inc. as a Sr. Director handling
                  global accounts and prior to that he was a Chief Technology
                  Officer (CTO) in BTI Payments (White Label ATM Licensee). He
                  also headed Technology during his stint in Hitachi Payments.{" "}
                </span>
              </p>
              <p className=" flex  my-2">
                <ArrowCircleRightIcon className="w-6 h-6 text-blue-500" />
                <span className="ml-2">
                  During his 27 years of career experience, he has been
                  instrumental in handling large and complex projects, setting
                  up Technology shop and have been involved in many of the
                  innovative solutions such as Railway Pass Printing from ATM,
                  Operating ATM on CDMA network, Implementing Mobile Pos (a.k.a.
                  MPos), Operationalising EFT Switch with frictionless and cost
                  effective migration process and many more.{" "}
                </span>
              </p>
              <p className=" flex  my-2">
                <ArrowCircleRightIcon className="w-6 h-6 text-blue-500" />
                <span className="ml-2">
                  Sachin is a Commerce graduate and holds a Masters In Computer
                  Science (Datapro) and is also PMP certified from PMI (USA).{" "}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferingData;
