import React from "react";
import nodejs from "../assests/nodejs.png";
import java from "../assests/java.png";
import python from "../assests/python.png";
import react from "../assests/react.png";
import angular from "../assests/angular.png";
import android from "../assests/android.jpg";
export const OurTechStack = () => {
  return (
    <div>
      <div>
        <div className="container mx-auto" onMouseDown={(e)=>e.preventDefault()} onContextMenu={(e)=>e.preventDefault()}>
          <div className="flex flex-col lg:flex-row justify-center items-center py-12 mx-4 md:mx-6 ">
            <div className="lg:w-1/2 flex flex-col justify-center items-center lg:items-start">
              <h1 className="text-3xl lg:text-4xl xl:text-5xl font-bold text-gray-800 text-center lg:text-left">
                Our Tech Stack
              </h1>
              <p className="text-base leading-normal text-gray-600 mt-4 md:w-8/12 lg:w-11/12 text-center lg:text-left">
                We focus on emerging technologies for building high performing
                web & mobile products that can be securely deployed in the
                cloud. We enable SMEs-"Small and Medium Enterprises" in making
                the best use of emerging digital technologies to build stronger
                capabilities & seize growth opportunities in today’s
                ever-evolving digital economy.
              </p>
            </div>
            <div
              role="list"
              aria-label="Team members"
              className="lg:w-1/2 grid grid-cols-3 sm:grid-cols-4 md:grid-cols-3 gap-x-12 gap-y-12 md:gap-x-12 mt-10 lg:mt-0 sm:w-auto w-full"
            >
              <div
                role="listitem"
                className="flex justify-center items-center "
              >
                <img src={java} className="w-20 h-20 " alt="display avatar" />
              </div>
              <div
                role="listitem"
                className="flex justify-center items-center "
              >
                <img src={nodejs} className="w-20 h-20 " alt="display avatar" />
              </div>
              <div
                role="listitem"
                className="flex justify-center items-center "
              >
                <img src={python} className="w-20 h-20 " alt="display avatar" />
              </div>
              <div
                role="listitem"
                className="flex justify-center items-center "
              >
                <img
                  src={android}
                  className="w-20 h-20 "
                  alt="display avatar"
                />
              </div>
              <div
                role="listitem"
                className="flex justify-center items-center "
              >
                <img
                  src={angular}
                  className="w-20 h-20 rounded-full"
                  alt="display avatar"
                />
              </div>
              <div
                role="listitem"
                className="flex justify-center items-center "
              >
                <img
                  src={react}
                  className="w-20 h-20 rounded-full"
                  alt="display avatar"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
