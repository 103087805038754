import React from "react";
// import md from "../assests/schnsir.svg";
import service1 from "../assests/services2.jpg";
import alisir from "../assests/alisir.png";
import { ArrowCircleRightIcon } from "@heroicons/react/solid";
const AliSirData = () => {
  return (
    <div className="flex items-center flex-col justify-center w-full  ">
      {/* Card code block start */}
      <div id="sachinkoti" className="bg-white w-full  shadow rounded">
        <div className="relative">
          <img
            className="h-56 shadow  w-full object-cover object-center"
            src={service1}
            alt="loading..."
          />
          <div className="inset-0 m-auto w-24 h-24 absolute bottom-0 -mb-12 xl:ml-10  border-2 shadow border-white rounded-full">
            <img
              className="w-full h-full overflow-hidden object-cover rounded-full"
              src={alisir}
              alt="loading..."
            />
          </div>
        </div>
        <div className="px-5 xl:px-10 pb-10 py-2">
          <div className="pt-3 xl:pt-5 flex flex-col xl:flex-row items-start xl:items-center justify-between">
            <div className="xl:pr-16 w-full  py-8">
              <div className="text-center xl:text-left mb-3 xl:mb-0 flex flex-col xl:flex-row items-center justify-between xl:justify-start">
                <h2 className="mb-2 xl:mb-0 xl:mr-4 text-3xl uppercase font-semibold  dark:text-gray-900  tracking-normal">
                  MIR AMEER ALI
                </h2>
                <div className="text-sm bg-indigo-700 dark:bg-indigo-600 text-white px-5 py-1 font-normal rounded-full">
                  DIRECTOR
                </div>
              </div>
              <p className=" flex  my-2 w-full">
                <ArrowCircleRightIcon className="w-6 h-6 text-blue-500" />
                <span className="ml-2">
                  Mir Ameer Ali started his career in banking and worked in
                  operations, credit and technology functions. During his 15
                  years in Bank, Mr Ali was responsible for automation of
                  various bank/branch functions. During this time, he was
                  selected as a member of IBA’s technology committee to setup
                  shared payment network system (SPNS) for ATMs( SWADHAN).
                </span>
              </p>
              <p className=" flex  my-2">
                <ArrowCircleRightIcon className="w-6 h-6 text-blue-500" />
                <span className="ml-2">
                  After his banking career, Mr Ali held leadership positions in
                  reputed Indian/Multinational Technology Companies in India and
                  abroad. His stint at NCR India included heading payment
                  solutions and also heading Retail Automation Division, he was
                  responsible for setting up Centre of Expertise(CoE) for Petro
                  Retail Automation for NCR in India.
                </span>
              </p>
              <p className=" flex  my-2">
                <ArrowCircleRightIcon className="w-6 h-6 text-blue-500" />
                <span className="ml-2">
                  He is passionate about leveraging technology to solve business
                  issues and improve operational efficiencies. He setup
                  Knowledge Center for Care Ratings( he was CTO/CIO at CARE
                  Ratings).
                </span>
              </p>
              <p className=" flex w-full my-2">
                <ArrowCircleRightIcon className="w-6 h-6 text-blue-500" />
                <span className="ml-2">
                  At FIS India, he was responsible for creating the payments
                  infrastructure in North Eastern States and Jammu & Kashmir.
                </span>
              </p>
              <p className=" flex  my-2">
                <ArrowCircleRightIcon className="w-6 h-6 text-blue-500" />
                <span className="ml-2">
                  With over 3 decades of financial and technology experience, Mr
                  Ali mentors start-ups in technology and payments space.
                </span>
              </p>
              <p className=" flex  my-2">
                <ArrowCircleRightIcon className="w-6 h-6 text-blue-500" />
                <span className="ml-2">
                  Mr Ali is a post graduate in Science, holds a Diploma in
                  computers( Aptech) and a management ( IIT-Mumbai){" "}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AliSirData;
